"use strict";

Drupal.behaviors.blockMenuSecondary = {
  attach: function (context, settings) {
    
    jQuery(".block--menu-secondary .phone").removeClass("active");
    jQuery(".block--menu-secondary .phone a").removeClass("active").attr("href", "tel:" + Drupal.settings.agence.phone);

  }
};

"use strict";

Drupal.behaviors.slick = {
  attach: function (context, settings) {

    if (jQuery(".block--banner--front__slider", context).length > 0) {
      jQuery('.block--banner--front__slider').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        fade: true,
        adaptiveHeight: true
      });
    }
    
    /* Slick Gallery */
    jQuery(".slick-gallery .main-slider .slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      fade: true,
      swipe: false,
      autoplay: false,
      autoplaySpeed: 4000,
      adaptiveHeight: true,
      asNavFor: ".slick-gallery .nav-slider .slider"
    });
    jQuery(".slick-gallery .nav-slider .slider").slick({
      asNavFor: ".slick-gallery .main-slider .slider",
      slidesToShow: 3,
      slidesToScroll: 1,
      focusOnSelect: true,
      dots: false,
      arrows: true,
      swipe: false,
      prevArrow: "<span><i class='fa fa-angle-left'></i></span>",
      nextArrow: "<span><i class='fa fa-angle-right'></i></span>",
      appendArrows: ".slick-gallery .nav-slider .slider-arrows"
    });
    jQuery(".slick-gallery .main-slider .slider .slick-slide img").click(function() {
      jQuery(".originals a." + jQuery(this).attr("class")).click();
    });
    jQuery(".slick-gallery .nav-slider .slider .slick-slide .icon").click(function() {
      jQuery(".originals a." + jQuery("img", jQuery(this).parents(".thumb")).attr("class")).click();
    });

  }
};

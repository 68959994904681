"use strict";

Drupal.behaviors.blockMenuMain = {
  attach: function (context, settings) {

    jQuery(".block--menu-main ul li").hover(function(event) {
      event.preventDefault();
      if (jQuery("ul", jQuery(event.currentTarget)).length) {
        jQuery(event.currentTarget).addClass("on");
      }
    }, function(event) {
      event.preventDefault();
      jQuery(event.currentTarget).removeClass("on");
    });

    jQuery(".block--menu-main").scrollToFixed();
  }
};

"use strict";

Drupal.behaviors.share = {
  attach: function (context, settings) {
    jQuery(".share").on("click", function(event) {
      jQuery.fancybox.open({
        src: '.block--share',
        type: 'inline',
        smallBtn: false
      });

      return false;
    });

    jQuery(".block--share__copy .btn").on("click", function() {
      let copyText = document.getElementById("block--share__copy__value");
      copyText.select();
      document.execCommand("copy");

      jQuery(".block--share__copy .btn").text("Copié !");

      return false;
    });
  }
};

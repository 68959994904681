"use strict";

// import Swiper, { Navigation, Pagination } from 'swiper';
// Swiper.use([Navigation, Pagination]);
import Swiper from 'swiper';

Drupal.behaviors.swiper = {
  attach: function (context, settings) {


    // IMMOBILIER : Promoted to front page
    if (jQuery(".block--view-immobilier-front .swiper-container", context).length > 0) {
      const immobilierSwiper = new Swiper(".block--view-immobilier-front .swiper-container", {
        autoHeight: true,
        slidesPerView: 5,
        slidesPerGroup: 1,
        spaceBetween: 30,
        loop: true,
        initialSlide: 0,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        allowTouchMove: true,
        centeredSlides: false,
        autoplay: {
          delay: 5000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1680: {
            slidesPerView: 4
          },
          1289: {
            slidesPerView: 3
          },
          991: {
            slidesPerView: 1
          }
        }
      });
    }
  }
};

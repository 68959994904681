"use strict";

Drupal.behaviors.tabs = {
  attach: function (context, settings) {

    jQuery(".node--immobilier--full .tabs").tabs({
      activate: function(event, ui) {
        if(jQuery(".tabs .js-gmap").length) {

        }
      }
    });

  }
};

"use strict";

Drupal.behaviors.blockMenuMobile = {
  attach: function (context, settings) {

    jQuery("#sidr .sidr__content__menu-main").append(jQuery(".region--header .block--menu-main > .container > ul").clone());
    jQuery("#sidr .sidr__content__menu-secondary").append(jQuery(".region--header .block--header__links > ul").clone());
    jQuery("#sidr .sidr__content__menu-secondary a").removeClass("ml-2").removeClass("ml-4");
    jQuery("#sidr").css("display", "flex");

    jQuery("#sidr li.active, #sidr li.active-trail, #sidr a.active, #sidr a.active-trail").addClass("on").removeClass("active");
    jQuery("#sidr .sidr__content__menu-main").slinky({
      resize: true,
      theme: ""
    });

    jQuery(".sidr__content__menu-group select").change(function() {
      window.location = jQuery(this).val();
    });

    jQuery("a.sidr-trigger__button").sidr({
      side: "right",
      displace: false,
      onOpen: function() {
        jQuery("html, body").css("overflow", "hidden");
      },
      onClose: function() {
        jQuery("html, body").css("overflow", "auto");
      }
    });

    jQuery("#sidr a.sidr__header__close").on("click", function() {
      jQuery.sidr("close");
    });
  }
};

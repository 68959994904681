// Libraries
import "@fancyapps/fancybox";
import "slick-carousel";
import "select2";
import "sidr/dist/jquery.sidr";
import "jquery-slinky/dist/slinky.min";
import "scrolltofixed/jquery-scrolltofixed-min";
require("jquery-ui/ui/widgets/tabs");
require("jquery-ui/ui/widgets/slider");

import "./behaviors/blockMenuSecondary";
import "./behaviors/blockMenuMain";
import "./behaviors/blockMenuMobile";
import "./behaviors/immobilierSearchSortForm";
import "./behaviors/loanSimulatorForm";
import "./behaviors/immobilierContent";
// import "./behaviors/locateAgency";
// import "./behaviors/multistepForm";
// import "./behaviors/bottomLinks";
import "./behaviors/select2";
import "./behaviors/share";
import "./behaviors/slick";
import "./behaviors/swiper";
import "./behaviors/tabs";
// import "./behaviors/userRegister";
// import "./behaviors/userLogin";
// import "./behaviors/userPassword";
import "./behaviors/immobilierSearchForm";
"use strict";

Drupal.behaviors.loanSimulatorForm = {
  attach: function (context, settings) {
    
    jQuery("#slider-price").slider({
      range: "min",
      value: jQuery("#loan-simulator-form #edit-price").val(),
      min: 0,
      step: 100,
      max: 2000000,
      slide: function(event, ui) {
        jQuery("#slider-price-val .val").html(ui.value + " €");
        jQuery("#loan-simulator-form #edit-price").val(ui.value);
      }
    });
    jQuery("#slider-price-val .val").html(jQuery("#slider-price").slider("value") + " €");
    jQuery("#slider-price").val(jQuery("#slider-price").slider("value") + " €");
    

    jQuery("#slider-duration").slider({
      range: "min",
      value: jQuery("#loan-simulator-form #edit-duration").val(),
      min: 1,
      max: 30,
      slide: function(event, ui) {
        if (ui.value > 1) {
          jQuery("#slider-duration-val .val").html(ui.value + " ans");
        }
        else {
          jQuery("#slider-duration-val .val").html(ui.value + " an");
        }
        jQuery("#loan-simulator-form #edit-duration").val(ui.value);
      }
    });
    if (jQuery("#slider-duration").slider("value") > 1) {
      jQuery("#slider-duration-val .val").html(jQuery("#slider-duration").slider("value") + " ans");
    }
    else {
      jQuery("#slider-duration-val .val").html(jQuery("#slider-duration").slider("value") + " an");
    }
    jQuery("#slider-duration").val(jQuery("#slider-duration").slider("value"));
    

    jQuery("#slider-rate").slider({
      range: "min",
      value: jQuery("#loan-simulator-form #edit-rate").val(),
      min: 0,
      max: 2.5,
      step: 0.01,
      slide: function(event, ui) {
        jQuery("#slider-rate-val .val").html(ui.value + " %");
        jQuery("#loan-simulator-form #edit-rate").val(ui.value);
      }
    });
    jQuery("#slider-rate-val .val").html(jQuery("#slider-rate").slider("value") + " %");
    jQuery("#slider-rate").val(jQuery("#slider-rate").slider("value") + " %");


    jQuery(".loan-simulator-form .form-submit").click(function(event) {
      event.preventDefault();

      jQuery(".loan-simulator-form .overlay").fadeIn(400, function() {
        window.setTimeout(function() {
          var result = calculateLoan(jQuery("#edit-price").val(), jQuery("#edit-duration").val(), jQuery("#edit-rate").val());

          jQuery(".loan-simulator-results .loan .value").html(result[0] + " &euro;");
          jQuery(".loan-simulator-results .duration .value").html(result[1] + " ans");
          jQuery(".loan-simulator-results .monthly-payments .value").html(result[2] + " &euro;");

          jQuery(".loan-simulator-form .form-step__content").slideUp(400, function() {
            jQuery(".loan-simulator-form .form-step__content").css("display", "none");
          });

          jQuery(".loan-simulator-form .loan-simulator-results").slideDown(400, function() {
            jQuery(".loan-simulator-form .overlay").fadeOut();
          });
        }, 2000);
      });

      return false;
    });

    jQuery(".loan-simulator-form .reload").click(function(event) {
      event.preventDefault();

      jQuery(".loan-simulator-form .overlay").fadeIn(400, function() {
        window.setTimeout(function() {
          jQuery(".loan-simulator-form .loan-simulator-results").slideUp(400, function() {
            jQuery(".loan-simulator-form .loan-simulator-results").css("display", "none");
          });

          jQuery(".loan-simulator-form .form-step__content").slideDown(400, function() {
            jQuery(".loan-simulator-form .overlay").fadeOut();
          });
        }, 100);
      });

      return false;
    });

  }
};

function PMT(PV, Rate, nper) {
  var pmt = (PV * Rate) / (1 - Math.pow(1 + Rate, -nper));
  return arrondi(pmt, 0);
}

function arrondi(Nombre, decimales) {
  var temp1 = Nombre * Math.pow(10, decimales);
  var temp2 = Math.round(temp1);
  var temp3 = temp2 / Math.pow(10, decimales);
  return (temp3);
}

function calculateLoan(price, duration, rate) {
  var result_duration;
  var result_monthly_payment;

  price = (price == "") ? 0 : price;

  result_duration = duration;
  result_duration = result_duration == "" ? "0" : result_duration;

  result_monthly_payment = PMT(price, (rate/100/12), (duration*12));
  result_monthly_payment = result_monthly_payment == "" ? "0" : result_monthly_payment;
  result_monthly_payment = result_monthly_payment == "Infinity" ? "0" : result_monthly_payment;

  return [
    price,
      result_duration,
      result_monthly_payment
  ];
}


"use strict";

Drupal.behaviors.select2 = {
  attach: function (context, settings) {
    var $selects = [];
    jQuery(".select2").each(function(index, element) {
      var select2_theme = "bootstrap4";
      var form_select = jQuery(element);
      var form_select_id = form_select.attr("id");
      var placeholder = "";

      var WithSearchField = 999999;
      if (form_select.hasClass("select2--filter")) {
        WithSearchField = 0;
      }

      if (form_select.data("placeholder") != "") {
        placeholder = form_select.data("placeholder");
      }

      $selects[form_select_id] = form_select.select2({
        width: "100%",
        theme: select2_theme,
        minimumResultsForSearch: WithSearchField,
        placeholder: "- Sélectionner -"
      }).on("select2:select", function(e) {

        $selects[form_select_id].parent(".form-item").removeClass("focus");

        var data = e.params.data;
        if (data.text == "") {
          $selects[form_select_id].parent(".form-item").removeClass("filled").addClass("empty");
        } else {
          $selects[form_select_id].parent(".form-item").removeClass("empty").addClass("filled");
           if ($selects[form_select_id].val()) {
              $selects[form_select_id].parent(".form-item").removeClass("invalid");
           }
        }

      }).on("select2:open", function(e) {
        $selects[form_select_id].parent(".form-item").addClass("focus");
      }).on("select2:close", function(e) {
        $selects[form_select_id].parent(".form-item").removeClass("focus");
      });

      $selects[form_select_id].siblings("label").on("click", function(e) {
        $selects[form_select_id].select2("open");
        return false;
      });
    });
    
    jQuery("#immobilier-search-form select").select2({
      width: "100%",
      theme: "bootstrap4 bootstrap4--dark",
      placeholder: "- Sélectionner -",
      minimumResultsForSearch: 9999999,
    });
  }
};

"use strict";

Drupal.behaviors.immobilierSearchSortForm = {
  attach: function (context, settings) {

    jQuery(".immobilier-search-sort-form #edit-sort").on("select2:select", function(e) {
      window.location = jQuery(".immobilier-search-sort-form #edit-sort").val();
    })

  }
};

"use strict";

Drupal.behaviors.immobilierContent = {
  attach: function (context, settings) {
    if (jQuery(window).width() >= 1290) {
      jQuery(".node--immobilier--full .block--banner-contact").scrollToFixed({
        marginTop: 90,
        limit: function () {
          return (jQuery(".node--immobilier--full").offset().top + jQuery(".node--immobilier--full").outerHeight()) - jQuery(".node--immobilier--full .block--banner-contact").outerHeight();
        },
        zIndex: 1,
        removeOffsets: true
      });
    }
  }
};

"use strict";

Drupal.behaviors.immobilierSearchForm = {
  attach: function (context, settings) {

    jQuery(".immobilier-search-form__map__svg path", context).on("click", function(e) {
      jQuery(".immobilier-search-form__map__svg path").removeAttr("class");
      jQuery(e.currentTarget).attr("class", "active");
      jQuery(".immobilier-search-form #edit-region").val(jQuery(e.currentTarget).data("tid")).trigger("change");
    });

    jQuery(".immobilier-search-form #edit-transaction", context).on("change", function(e) {
      const options = {
        data: [],
        width: "100%",
        theme: "bootstrap4",
        minimumResultsForSearch: true,
        placeholder: "Type de logement",
        disabled: false
      };

      if (jQuery(this).val() == 43) {
        options.disabled = true;
      }

      jQuery(".immobilier-search-form #edit-type", context).select2('destroy');
      jQuery(".immobilier-search-form #edit-type", context).val("").select2(options);
    });

    jQuery(".immobilier-search-form #edit-transaction").trigger("change");

  }
};
